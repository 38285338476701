@tailwind base;
@tailwind components;

.Modal {
  /* position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px; */
  /* background-color: papayawhip; */
}

.Overlay {
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: transparent;
  transition: background-color 150ms;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  overflow-y: scroll;
}

.ReactModal__Overlay--after-open.Overlay {
  background-color: rgba(0, 0, 0, 0.5);
}
.ReactModal__Overlay--before-close.Overlay {
  background-color: transparent;
}

.Modal.ReactModal__Content {
  opacity: 0;
  transform: scale(0.8) translateY(100px);
  margin: 4rem 0;
}

.Modal.ReactModal__Content--after-open {
  opacity: 1;
  transform: scale(1) translateY(0);
  transition: opacity 150ms, transform 150ms;
}

.Modal.ReactModal__Content--before-close {
  opacity: 0;
  transform: scale(0.7) translateY(100px);
}

@tailwind utilities;

@keyframes rotate-forever {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* NB: This is done for the sake of class precedence */
.seriously-translate-x-0 {
  @apply translate-x-0;
}
