:root {
  --tw-purple-100: #ede9fe;
  --tw-purple-300: #c4b5fd;
  --tw-blue-100: #dbeafe;
  --tw-blue-300: #93c5fd;
  --tw-red-100: #fee2e2;
  --tw-red-300: #fca5a5;
  --tw-indigo-100: #e0e7ff;
  --tw-indigo-300: #a5b4fc;
}

.tooltip-container {
  --tooltip-bg-color: var(--tw-red-100);
  --tooltip-border-color: var(--tw-red-300);
  --tooltipBackground: var(--tooltip-bg-color);
  --tooltipBorder: var(--tooltip-border-color);
  background-color: var(--tooltip-bg-color);
  border: 1px solid var(--tooltip-border-color);
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  padding: 0.4rem;
  transition: opacity 0.3s;
  z-index: 9999;
}

.tooltip-container.purple, .tooltip-arrow.purple {
  --tooltip-bg-color: var(--tw-purple-100);
  --tooltip-border-color: var(--tw-purple-300);
}

.tooltip-container.blue, .tooltip-arrow.blue {
  --tooltip-bg-color: var(--tw-blue-100);
  --tooltip-border-color: var(--tw-blue-300);
}

.tooltip-container.red, .tooltip-arrow.red {
  --tooltip-bg-color: var(--tw-red-100);
  --tooltip-border-color: var(--tw-red-300);
}

.tooltip-container.indigo, .tooltip-arrow.indigo {
  --tooltip-bg-color: var(--tw-indigo-100);
  --tooltip-border-color: var(--tw-indigo-300);
}

.tooltip-arrow {
  height: 1rem;
  position: absolute;
  width: 1rem;
}

.tooltip-arrow::before {
  border-style: solid;
  content: "";
  display: block;
  height: 0;
  margin: auto;
  width: 0;
}

.tooltip-arrow::after {
  border-style: solid;
  content: "";
  display: block;
  height: 0;
  margin: auto;
  position: absolute;
  width: 0;
}

.tooltip-arrow[data-placement*="bottom"] {
  height: 1rem;
  left: 0;
  margin-top: -0.4rem;
  top: 0;
  width: 1rem;
}

.tooltip-arrow[data-placement*="bottom"]::before {
  border-color: transparent transparent silver transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
  position: absolute;
  top: -1px;
}

.tooltip-arrow[data-placement*="bottom"]::after {
  border-color: transparent transparent white transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
}

.tooltip-arrow[data-placement*="top"] {
  bottom: 0;
  height: 1rem;
  left: 0;
  margin-bottom: -1rem;
  width: 1rem;
}

/* Back part (seen as the border color) */
/* Base Style */
.tooltip-arrow[data-placement*="top"]::before {
  border-color: var(--tooltip-border-color) transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
  position: absolute;
  top: 1px;
}

/* Front part (seen as the background color) */
/* Base Style */
.tooltip-arrow[data-placement*="top"]::after {
  border-color: var(--tooltip-bg-color) transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
}

.tooltip-arrow[data-placement*="right"] {
  height: 1rem;
  left: 0;
  margin-left: -0.7rem;
  width: 1rem;
}

.tooltip-arrow[data-placement*="right"]::before {
  border-color: transparent silver transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
}

.tooltip-arrow[data-placement*="right"]::after {
  border-color: transparent white transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
  left: 6px;
  top: 0;
}

.tooltip-arrow[data-placement*="left"] {
  height: 1rem;
  margin-right: -0.7rem;
  right: 0;
  width: 1rem;
}

.tooltip-arrow[data-placement*="left"]::before {
  border-color: transparent transparent transparent silver;
  border-width: 0.5rem 0 0.5rem 0.4em;
}

.tooltip-arrow[data-placement*="left"]::after {
  border-color: transparent transparent transparent white;
  border-width: 0.5rem 0 0.5rem 0.4em;
  left: 3px;
  top: 0;
}
